import request from '@/utils/request'

export function country_getData() {
  return request({
    url: '/center/data/getAllShowData',
    method: 'get',
  })
}

export function country_getDynaData() {
  return request({
    url: '/center/data/getDynaData',
    method: 'get',
  })
}
